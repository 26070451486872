<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách nhóm người dùng hợp lệ</h4>
      </div>
      <div class="listwork__table__01">
        <!-- ===============================================bắt dầu bảng nhóm người dùng hợp lệ-------------------------------- -->
        <vue-good-table
          :select-options="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          max-height="100%"
          style-class="table-listwork"
        >
          >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'score'">
              <span style="font-weight: bold; color: blue;">tiennguyen</span>
            </span>
          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm người dùng hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} nhóm người dùng</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách nhóm người dùng không hợp lệ</h4>
        </div>
        <!-- ================================================nút chỉnh sửa  -------------------------------- -->
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- ================================================nút kiểm tra  -------------------------------- -->
          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>

          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <!-- ===============================================bắt dầu bảng nhóm người dùng không hợp lệ-------------------------------- -->
        <vue-good-table
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          style-class="table-listwork"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span v-if="props.column.field == 'icon02'">
              <feather-icon
                v-b-tooltip.hover.right.html="props.row.messErr[0].message"
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <span
              v-if="props.column.field == 'code' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code,}"
            >
              {{ props.row.code }}
            </span>

            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue($event, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>
            <span
              v-if="props.column.field == 'name' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >
              {{ props.row.name }}
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue($event, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'description'">
              <span>{{ props.row.description }}</span>
            </span>
            <span v-if="props.column.field == 'requirement'">
              <span>{{ props.row.requirement }}</span>
            </span>
          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm người dùng không  hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} nhóm người dùng</p>
      </div>

      <div class="listwork__button">
        <!-- ================================================nút thêm nhóm người dùng-------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addPositionJob()"
          >
            Thêm nhóm người dùng
          </b-button>
        </div>
        <!-- ================================================nút hủy bỏ  -------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeExcel"
          >
            Hủy bỏ
          </b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã nhóm người dung',
          field: 'code',
        },
        {
          label: 'tên nhóm người dùng',
          field: 'name',
        },
        {
          label: 'Mô tả nhóm người dùng',
          field: 'description',
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'Mã nhóm người dung',
          field: 'code',
        },
        {
          label: 'tên nhóm người dùng',
          field: 'name',
        },
        {
          label: 'Mô tả nhóm người dùng',
          field: 'description',
        },
      ],
      dataCheck: [],
    }
  },
  computed: {
    ...mapState('userGroup', ['dataValidUserGroup', 'dataNoValidUserGroup']),
  },
  created() {
    this.dataInvalid = this.dataValidUserGroup
    this.dataNoInvalid = this.dataNoValidUserGroup
  },
  methods: {
    ...mapActions('userGroup', ['getApiExcelUserGroup']),
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event.target.value
    },

    async CheckdataNoInvalid() {
      if (this.dataInvalid.length > 0) {
        this.model = {
          listExcel: this.dataInvalid.concat(this.dataNoInvalid),
          isValidate: true,
        }
      } else {
        this.model = {
          listExcel: this.dataInvalid.concat(this.dataNoInvalid),
          isValidate: true,
        }
      }
      const valuedata = await this.getApiExcelUserGroup(this.model)
      if (valuedata.oke) {
        this.dataInvalid = valuedata.data.filter(x => x.isSuccess === true)
        this.dataNoInvalid = valuedata.data.filter(x => x.isSuccess === false)
      } else {
        this.$root.$bvToast.toast('Lỗi không thể kiểm tra!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async addPositionJob() {
      if (this.dataInvalid && this.dataInvalid.length !== 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        const res = await this.getApiExcelUserGroup(model)
        if (res.oke) {
          this.dataInvalid = []
          if (this.dataNoInvalid && this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'User-Group-List' })
          }
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast('Thêm không thành công!', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } else {
        this.$root.$bvToast.toast('Không có dữ liệu để thêm!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    closeExcel() {
      this.dataInvalid = ''
      this.dataNoInvalid = ''
      this.$router.push({ name: 'User-Group' })
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}

</style>
